import React, { useState, useContext } from 'react'
import { graphql } from 'gatsby'
import Img from 'gatsby-image'
import BlockContent from '../components/block-content'
import GraphQLErrorList from '../components/graphql-error-list'
import SEO from '../components/seo'
import Layout from '../containers/layout'
import Images from '../components/images'
import BackgroundImage from 'gatsby-background-image'

export const query = graphql`
  query ReferenzenPageQuery {
    referenz: sanityBackgrounds {
      referenzPage {
        _key
        _type
        alt
        asset {
          fluid {
            base64
            aspectRatio
            src
            srcSet
            srcWebp
            srcSetWebp
            sizes
          }
        }
      }
    }

    site: sanitySiteSettings(_id: { regex: "/(drafts.|)siteSettings/" }) {
      title
      description
      keywords
    }
    page: sanityPage(_id: { regex: "/(drafts.|)referenzen/" }) {
      id
      title
      _rawPortablebody
      slideshow {
        slides {
          asset {
            id
            url
            fluid {
              base64
              aspectRatio
              src
              srcSet
              srcWebp
              srcSetWebp
              sizes
            }
          _id
          }
          _key
          hotspot {
            _key
            _type
            x
            y
            height
            width
          }
          crop {
            _key
            _type
            top
            bottom
            left
            right
          }
          alt
          caption
        }
      }
    }
  }
`

const ReferenzenPage = props => {
  const { data, errors } = props

  if (errors) {
    return (
      <Layout>
        <GraphQLErrorList errors={errors} />
      </Layout>
    )
  }

  const referenz = (data || {}).referenz
  const site = (data || {}).site

  if (!site) {
    throw new Error(
      'Missing "Site settings". Open the studio at http://localhost:3333 and add some content to "Site settings" and restart the development server.'
    )
  }

  const page = data && data.page

  if (!page) {
    throw new Error(
      'Missing "referenzen" page data. Open the studio at http://localhost:3333 and add "About" page data and restart the development server.'
    )
  }

  return (
    <Layout pageLayout='normal-footer'>
      <SEO title={site.title} description={site.description} keywords={site.keywords} />
      <article className='sections'>
        <section className='page-section white herstellung'>
          <div className='mainImage hero--subpage hero'>
            <BackgroundImage
              Tag='section'
              style={{
                backgroundSize: 'cover',
                backgroundPosition: 'center center',
                backgroundRepeat: 'none',
                display: 'block',
                position: 'fixed',
                height: '100%',
                width: '100%',
                willChange: 'transform'
              }}
              fluid={referenz.referenzPage.asset.fluid}
              backgroundColor={`#040e18`}
              fadeIn={false}
            />
            <div className='content-wrapper design-page'>
              <div className='content'>
                <div className='normal-sections-wrapper'>
                  <div className='subsection'>
                    <h1>{page.title}</h1>
                    {page._rawPortablebody && <BlockContent blocks={page._rawPortablebody || []} />}
                    {page.slideshow && <Images data={page.slideshow || []} />}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </article>
    </Layout>
  )
}

export default ReferenzenPage
